@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&family=Libre+Franklin:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&family=Fira+Code&display=swap');

body {
  background-color: #221F23!important;
  margin: 0;
  font-family: 'EB Garamond';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 { 
    font-family: 'Libre Franklin';
    font-weight: 400;
}

h2 { 
    font-family: 'Libre Franklin';
    font-weight: 400;
    opacity: 0.75;
    text-transform: uppercase;
    font-size: 28px!important;
}

h3 { 
    font-family: 'Libre Franklin';
    font-weight: 400;
    font-size: 24px!important;
}

p,li{ 
    font-family: 'EB Garamond';
    font-size: 18px!important;
}

code {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card-title { 
    font-family: "Libre Franklin";
}

.root { 
    height: 100vh!important;
    background-color: #221F23;
}

a { 
    color: #3477a7!important;
}

.crt-post-header, .crt-post-userimage, .crt-post-share, .crt-logo.crt-tag {
    display: none
}

.crt-load-more-container { 
    font-family: 'Libre Franklin';
    font-weight: 400;
    float: right;
    padding-bottom: 20px;
}

.crt-post-text {
    margin-top: 5px;
    margin-bottom: -20px;
    padding-bottom: 0px;
    font-family: 'Libre Franklin';
    font-weight: 400;
}