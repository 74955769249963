.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1d2942;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.landing-left { 
    background-color: #221F23;
    color: #e7e7e7;
}

.landing-right { 
    background-color: #e7e7e7;
    /* color: ; */
}

.fullheight {
    height:100vh!important
}

.fullwidth {
    width:100%!important
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lhlinkouter { 
    font-size: 25;
    padding-bottom: 1vh;
    color:#FFD13C;
    font-family: "Fira Code";
}

.lhlink { 
    color: #e7e7e7;
}
.lhlink:hover { 
    color: #919191;
}

.landingcard { 
    width: 20vw!important;
    position: center;
    text-align: right;
    color: #221F23;
}

.landingcard:hover{
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    color: #221F23;
}

.card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 5vh; /* Added */
}

.vanity { 
    position: relative;
    padding-top: 20vh;
    padding-bottom: 5vh;
}

.polytropos { 
    font-size: 20;
    padding-bottom: 5vh;
}

.research-link { 
    margin-top: 30vh;
    width: 25vw!important;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto 
}